(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
(function($) {
	"use strict";
	var preloader = require("./hm_modules/preloader.js"),
		helpers = require("./hm_modules/helpers.js"),
		verticalParallax = require("./hm_modules/verticalParallax.js"),
		forms = require("./hm_modules/forms.js"),
		slider = require("./hm_modules/slider.js"),
		blogNavigation = require("./hm_modules/blogNavigation.js"),
		map = require("./hm_modules/gmap.js");


	// ==============================
	// App global parameters object
	// ==============================
	window.hm = {};

	window.hm.scrollBarWidth = helpers.getScrollbarWidth();
	window.hm.mobileSize = 480 - window.hm.scrollBarWidth;
	window.hm.tabletSize = 768 - window.hm.scrollBarWidth;
	window.hm.resizeLimit = 2000 - window.hm.scrollBarWidth;


	// ==============================
	// Load map
	// ==============================
	if($("#map_wrapper").length){
		google.maps.event.addDomListener(window, "load", map.init("map_wrapper"));
	}

	// ==============================
	// Animation
	// ==============================
	helpers.fadePageOn("a.preload-link");

	$("header .svg-heading, .talks .svg-heading, .talks .testimonial").animated("fadeInUp");
	$(".about-me__skills>div").animated("fadeInUp");
	$(".article, .portfolio-slider__navigation-container, .portfolio-slider__preview-container").animated("fadeIn");
	$(".portfolio-slider__projects-container").animated("fadeIn");

	$(".piechart .piechart__fill").animatePies();



	// ==============================
	// Parallax
	// ==============================

	// Main page mouse parallax
	if($("#scene.axis").length){
		$("#scene.axis").parallax({
			scalarX: 3,
			scalarY: 3,
			frictionX: 0.5,
			frictionY: 0.5
		});
	}

	// Common vertical parallax for other pages
	if($("#scene.vertical").length){
		// 5 - mobile fallback layer number
		verticalParallax.createParallax("#scene.vertical", ".layer", 5);

		// IE scroll jump fix
		if(helpers.ieVersion()) {
			$(".layer").css({transition:"transform .15s linear"});
			$("#scene.vertical").css({transition:"opacity .15s linear"});

			$("body").on("mousewheel", function () {
				event.preventDefault(); 

				var wheelDelta = event.wheelDelta,
					currentScrollPosition = window.pageYOffset;

				window.scrollTo(0, currentScrollPosition - wheelDelta);
			});
		}
	}

	// ==============================
	// Login card flip
	// ==============================
	$("#flip-card").click(function() {
		$("body").addClass("card_flipped");
	});

	$("#unflip-card").click(function(e) {
		e.preventDefault();
		$("body").removeClass("card_flipped");
	});



	// ==============================
	// Forms
	// ==============================
	if($("#contact").length){
		forms.contactForm("#contact", "#form-clear");
		forms.onAirCheck("#contact");


	}

	if($("#login").length){
		forms.authForm("#login", "#log-me", true, "shure");
		forms.onAirCheck("#login");
	}




	// ==============================
	// Main menu
	// ==============================
	$("#menu-toggle").click(function(){
		$(this).add(".main-menu, .header__top-row").toggleClass("active");
	});

	$(".main-menu__item").each(function(index) {
		var item_delay = 0.3+0.1*index;

		$(this).css("transition-delay", item_delay + "s");
	});




	// ==============================
	// Scroll buttons
	// ==============================
	$("button.go-down").click(function(){
		var go = $(this).data("link");
		$("html, body").stop().animate({
			scrollTop: $(go).offset().top
		}, 700, "swing");
	});

	$("button.go-up").click(function(){
		$("html, body").stop().animate({
			scrollTop: 0
		}, 700, "swing");
	});





	// ==============================
	// Slider
	// ==============================
	if($(".portfolio-slider").length){
		$(".portfolio-projects .project__title, .portfolio-projects .project__tech")
			.each(function() {
				slider.prepareTitles($(this), 700);
			});

		slider.createSlider(".portfolio-slider", 700);
	}





	// ==============================
	// Blog scroll events
	// ==============================
	if($(".blog-navigation").length){
		blogNavigation();
	}



	// ==============================
	// Contact form blur based on js
	// ==============================
	function set_bg(){
		var section = $(".talks"),
			form = section.find(".contact-form"),
			form_bg = form.find(".contact-form__bg"),
			bg_offset = section.offset().top - form_bg.offset().top;

		form_bg.css({
			"background-position" : "center " + bg_offset + "px"
		});

		// Upscale "testimonials" section background to fit its container
		if( $(window).width() > window.hm.resizeLimit){
			$(".talks, .contact-form__bg").css("background-size", $(window).width() + "px");
		}
	}

	if($(".talks").length){
		$(window).on("load", function() {
			set_bg();
		});

		$(window).resize(function() {
			set_bg();
		});
	}


	preloader();

})(jQuery);
},{"./hm_modules/blogNavigation.js":2,"./hm_modules/forms.js":3,"./hm_modules/gmap.js":4,"./hm_modules/helpers.js":5,"./hm_modules/preloader.js":7,"./hm_modules/slider.js":8,"./hm_modules/verticalParallax.js":9}],2:[function(require,module,exports){
function blogNavigation() {
	// ==============================
	// Save needed elements
	// ==============================
	var active_id,
		additional_offset = 60,
		menu = $(".blog-navigation"),
		menu_items = menu.find("li a"),
		scroll_items = menu_items.map(function(){
			var item = $($(this).attr("href"));
			if (item.length) return item;
		});



	// =====================================
	// Sidemenu link smoothscroll handler
	// =====================================
	menu_items.click(function(e){
		var href = $(this).attr("href"),
			offsetTop = (href === "#") ? 0 : $(href).offset().top - additional_offset;

		e.preventDefault();

		$("html, body").stop().animate({ 
			scrollTop: offsetTop
		}, 700, "swing");
	});



	// ======================================================
	// Articles scroll-spy handler and sidemenu positioning
	// ======================================================
	$(window).scroll(function() {
		var fromTop = $(this).scrollTop(),
			blog_nav_offset = $(".blog-navigation").offset().top,
			blog_nav_limit = $(".footer__wrapper").offset().top - $(".blog-navigation__wrapper").outerHeight(),
			current = scroll_items.map(function(){
				if ($(this).offset().top <= fromTop+additional_offset+1){
					return this;
				}
			});

		current = current[current.length-1];
		var id = current && current.length ? current[0].id : "";

		if (active_id !== id) {
			active_id = id;
			menu_items.removeClass("active").filter("[href=#"+id+"]").addClass("active");
		}

		// Sidemenu behaviour
		if(fromTop >= blog_nav_limit && $(window).width() > window.hm.tabletSize) {
			$(".blog-navigation__wrapper").css({"position":"absolute", "top":blog_nav_limit + "px"});
		} else if (fromTop >= blog_nav_offset && $(window).width() > window.hm.tabletSize) {
			$(".blog-navigation__wrapper").css({"position":"fixed", "top":0});
			$(".blog-navigation__wrapper").addClass("nav-fixed");
		} else {
			$(".blog-navigation__wrapper").css({"position":"relative"});
			$(".blog-navigation__wrapper").removeClass("nav-fixed");
		}
	});



	// =====================================
	// Adaptive sidemenu checker
	// =====================================
	$(window).resize(function() {
		if($(window).width() <= window.hm.tabletSize){
			$(".blog-navigation__wrapper").removeClass("nav-fixed");
			$(".blog-navigation__wrapper").css({"position":"relative"});
		} else {
			if($("body").scrollTop() >= $(".blog").offset().top){
				$(".blog-navigation__wrapper").css({"position":"fixed", "top":0});
				$(".blog-navigation__wrapper").addClass("nav-fixed");
			}
		}
	});



	// ==============================
	// Sidemenu toggle button
	// ==============================
	$(".blog-navigation__toggle").click(function(){
		$(".blog-navigation").toggleClass("active");
	});

};

module.exports = blogNavigation;
},{}],3:[function(require,module,exports){
// =======================================
// Variables and modules
// =======================================
var popup = require("./popup.js");

var match_arr = {
		"username" : /^[A-Za-zА-Яа-я0-9_-\s]{2,}$/g,
		"email" : /^([a-z0-9_\.-]+)@([a-z0-9_\.-]+)\.([a-z\.]{2,6})$/g,
		"text" : /.{10,}/g,
		"password" : /^([a-zA-Z0-9@*#]{6,18})$/g

	},
	errors_arr = {
		"username" : "Имя должно быть длиннее 2х символов и не содержать посторонних знаков",
		"email" : "Email имеет неверный формат<br/>Например: ex@mp.le",
		"text" : "Ваше сообщение должно быть не короче 10 символов"
	};

popup.init("#hm-popup", ".hm-popup__text", ".hm-popup__close");



// =======================================
// Hide tooltips
// =======================================
function hideTooplitp(){
	var field = $(this);

	if(field.next().hasClass("tooltip")){
		field.next().removeClass("show").addClass("hide");
	}
};



// =======================================
// Check text fields on air with regexp
// =======================================
function onAirCheck (form_selector){
	var fields = $(form_selector).find("input[type=text], input[type=email], input[type=password], textarea");

	fields.on("keyup", function(){
		var field = $(this),
			check_type = field.data("check"),
			value = field.val();

		if(value.match(match_arr[check_type])){
			field.removeClass("error").addClass("valid");
		}else{
			field.removeClass("valid").addClass("error");
		};

	});
};



// =======================================
// Check text fields on form submit
// =======================================
function textFieldsCheck (form) {
	var fields = form.find("input[type=text], input[type=email], textarea"),
		valid = true,
		tooltip_template = $("<div class='tooltip'></div>"),
		form_offset = form.offset().top;


	fields.each(function() {
		var field = $(this),
			check_type = field.data("check"),
			value = field.val(),
			tooltip_offset = field.offset().top - form_offset;

		if(!value.match(match_arr[check_type])){
			var field_tooltip;

			if(field.next().hasClass("tooltip")){
				field_tooltip = field.next();
			} else {
				field_tooltip = tooltip_template.clone();
				field.after(field_tooltip);
			}

			field_tooltip
				.css({"top" : tooltip_offset})
				.html(errors_arr[check_type])
				.removeClass("hide")
				.addClass("show");

			field.addClass("error");
			valid = false;
		}

	});

	return valid;
};



// =======================================
// Reset textfields
// =======================================
function resetForm (form){
	var maybe_airchecked_fields = form.find("input[type=text], input[type=email], input[type=password], textarea"),
		tooltips = form.find(".tooltip");

	maybe_airchecked_fields.removeClass("error valid");
	tooltips.removeClass("show").addClass("hide");
	form[0].reset();
}



// =======================================
// Send Feedback
// =======================================
function sendFeedback (form) {
	if(textFieldsCheck(form)){
		$.ajax({
			url: "/mail",
			type: "POST",
			data: form.serialize(),
			dataType: "json"
		}).done(function(resp) {
			popup.showPopup(resp.message, 2000);
			resetForm(form);
		}).fail(function(resp){
			popup.showPopup(resp.message);
			popup.showPopup("Упс. На сервере произошла ошибка.<br/>Попробуйте позже.", 2000);
			resetForm(form);
		});
		return false;
	} else {
		popup.showPopup("Пожалуйста исправьте ошибки заполнения.",2500);
	}
}



// =======================================
// Check robot
// =======================================
function checkRobot(form, checkbox_stat, radio_stat){
	var valid = false,
		human = $("input:checkbox").prop("checked"),
		radio = $("input:radio:checked").val();

	if(human===checkbox_stat && radio===radio_stat){
		valid = true;
	}else{
		popup.showPopup("Айзеку Азимову было бы стыдно за такого врушу",2500);
	}

	return valid;
}



// =======================================
// Process login
// =======================================
function authorization(form){
	$.ajax({
		type: "POST",
		url: "auth",
		data: form.serialize(),
		dataType: "json"
	}).done(function(resp) {
		if(resp.status=="denied"){
			popup.showPopup(resp.message,2000);
		} else {
			popup.showPopup(resp.message,2500);
			setTimeout(function(){
				window.location.href = "/admin";
			}, 3000)
		}
		resetForm(form);
	}).fail(function(){
		popup.showPopup("Упс. На сервере произошла ошибка.<br/>Попробуйте позже.");
		resetForm(form);
	});
	return false;
}



// =======================================
// Auth form watcher
// =======================================
function authForm (selector, login, checkbox_stat, radio_stat){
	var form = $(selector),
		button = form.find(login);

	form.on("submit", function(e){
		e.preventDefault();
		if(checkRobot(form, checkbox_stat, radio_stat)){
			authorization(form);
		} else {
			resetForm(form);
		}
	});
}



// =======================================
// Contact form watcher
// =======================================
function contactForm (selector, reset){
	var form = $(selector),
		reset = form.find(reset),
		fields = form.find("input[type=text], input[type=email], textarea");

	fields.on("focus", hideTooplitp);

	reset.on("click", function(e){
		e.preventDefault();
		resetForm(form);
	});

	form.on("submit", function(e) {
		e.preventDefault();
		sendFeedback(form);
	});
};



module.exports = {
	contactForm : contactForm,
	authForm : authForm,
	onAirCheck : onAirCheck
};
},{"./popup.js":6}],4:[function(require,module,exports){
var map;

function init(map_id) {
	var mapOptions = {
		center: new google.maps.LatLng(61.784974,34.347752),
		zoom: 14,
		zoomControl: false,
		disableDoubleClickZoom: false,
		mapTypeControl: false,
		scaleControl: false,
		scrollwheel: false,
		panControl: false,
		streetViewControl: false,
		draggable : true,
		overviewMapControl: false,
		overviewMapControlOptions: {
			opened: false,
		},
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		styles: [
		{
			"featureType": "administrative",
			"elementType": "labels.text.fill",
			"stylers": [
			{
				"color": "#444444"
			}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
			{
				"color": "#f2f2f2"
			}
			]
		},
		{
			"featureType": "poi",
			"elementType": "all",
			"stylers": [
			{
				"visibility": "off"
			}
			]
		},
		{
			"featureType": "road",
			"elementType": "all",
			"stylers": [
			{
				"saturation": -100
			},
			{
				"lightness": 45
			}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "all",
			"stylers": [
			{
				"visibility": "simplified"
			}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "labels.icon",
			"stylers": [
			{
				"visibility": "off"
			}
			]
		},
		{
			"featureType": "transit",
			"elementType": "all",
			"stylers": [
			{
				"visibility": "off"
			}
			]
		},
		{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
			{
				"color": "#455a64"
			},
			{
				"visibility": "on"
			}
			]
		}
		],
	}
	var mapElement = document.getElementById(map_id),
	map = new google.maps.Map(mapElement, mapOptions),
	locations = [
	['hm_', 'undefined', 'undefined', 'undefined', 'undefined', 61.77816170000001, 34.36403960000007, 'https://mapbuildr.com/assets/img/markers/ellipse-green.png']
	];

	for (i = 0; i < locations.length; i++) {
		if (locations[i][1] =='undefined') {
			description ='';
		} else {
			description = locations[i][1];
		}

		if (locations[i][2] =='undefined') {
			telephone ='';
		} else {
			telephone = locations[i][2];
		}
		if (locations[i][3] =='undefined') {
			email ='';
		} else {
			email = locations[i][3];
		}

		if (locations[i][4] =='undefined') {
			web ='';
		} else {
			web = locations[i][4];
		}

		if (locations[i][7] =='undefined') {
			markericon ='';
		} else {
			markericon = locations[i][7];
		}

		marker = new google.maps.Marker({
			icon: markericon,
			position: new google.maps.LatLng(locations[i][5], locations[i][6]),
			map: map,
			title: locations[i][0],
			desc: description,
			tel: telephone,
			email: email,
			web: web
		});
		link = '';
	}
}

module.exports ={
	init : init
};
},{}],5:[function(require,module,exports){
// ==============================
// Check scrollbar width
// ==============================
var getScrollbarWidth = function (){
	var style = {"max-height":"100px", "overflow":"scroll", "position":"absolute"},
		wrapper = $("<div id='scroll_bar_check_A'></div>").css(style),
		inner = $("<div id='scroll_bar_check_B'></div>"),
		stretcher = $("<img src='/assets/img/force-scroll.png'/>"),
		scrollBarWidth = 0;

	wrapper.append(stretcher).append(inner);
	$("body").append(wrapper);

	scrollBarWidth = wrapper.width()-inner.width();
	wrapper.remove();

	return scrollBarWidth;
};



// ==============================
// Check IE version
// ==============================
var ieVersion = function () {
	var ua = window.navigator.userAgent;

	var msie = ua.indexOf("MSIE ");
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
	}

	var trident = ua.indexOf("Trident/");
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf("rv:");
		return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
	}

	var edge = ua.indexOf("Edge/");
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
	}

	// other browser
	return false;
};



// ==============================
// Page changer
// ==============================
var fadePageOn = function(elSelector){
	$(document).on("click", elSelector, function(e) {
		var href = $(this).attr("href");
		e.preventDefault();

		return $("#preloader")
			.fadeIn(300, function(){
				return document.location = href != null ? href : "/";
			});
	});
};

module.exports = {
	getScrollbarWidth : getScrollbarWidth,
	ieVersion : ieVersion,
	fadePageOn : fadePageOn
}
},{}],6:[function(require,module,exports){
var popup,
	content,
	close,
	close_timeout;

function init(container, text, close){
	popup = $(container);
	content = popup.find(text);
	close = popup.find(close);

	close.on("click", closePopup);
};

function closePopup(){
	popup.addClass("hide").removeClass("show");
	if(close_timeout){
		clearTimeout(close_timeout);
	};
};


function showPopup (text, time){
	content.html(text);
	popup.removeClass("hide").addClass("show");

	if(time){
		close_timeout = setTimeout(function(){
			popup.addClass("hide").removeClass("show");
		}, time);
	}
};

module.exports = {
	init : init,
	showPopup : showPopup
};


},{}],7:[function(require,module,exports){
// ==========================================
// Preloader with percentage by image count
// ==========================================
var preloader_stat = $("#preloader-svg__percentage"),
	hasImageProperties = ["background", "backgroundImage", "listStyleImage", "borderImage", "borderCornerImage", "cursor"],
	hasImageAttributes = ["srcset"],
	match_url = /url\(\s*(['"]?)(.*?)\1\s*\)/g,
	all_images = [],
	total = 0,
	count = 0;

var circle_o = $("#preloader-svg__img .bar__outer"),
	circle_c = $("#preloader-svg__img .bar__center"),
	circle_i = $("#preloader-svg__img .bar__inner"),
	length_o = Math.PI*(circle_o.attr("r") * 2),
	length_c = Math.PI*(circle_c.attr("r") * 2),
	length_i = Math.PI*(circle_i.attr("r") * 2);



module.exports = function preloader() {

	function img_loaded(){
		var percentage = Math.ceil( (count+1) / total * 100 );

		count += 1;
		percentage = percentage > 100 ? 100 : percentage;

		// Draw offsets
		// 1st circle
		circle_o.css({strokeDashoffset: ((100-percentage)/100)*length_o });

		// when to start 2nd circle
		if(percentage > 50) {
			circle_c.css({strokeDashoffset: ((100-percentage)/100)*length_c });
		}

		// when to start 3rd circle
		if(percentage == 100) {
			circle_i.css({strokeDashoffset: ((100-percentage)/100)*length_i });
		}

		preloader_stat.html(percentage);

		if(count === total){
			return done_loading();
		}
	}

	function done_loading(){
		preloader_stat.css({"animation":"none"});
		$("#preloader").delay(700).fadeOut(700, function(){
			$("#preloader__progress").remove();

			if($(".flip-card").length){
				$(".flip-card").addClass("loaded");
			}
		});
	}

	function images_loop (total) {

		// Timeout version
		// setTimeout(function () {
		// 	var test_image = new Image();

		// 	test_image.onload = img_loaded;
		// 	test_image.onerror = img_loaded;

		// 	console.log("Count: " + count + " Total: " + total);

		// 	if (count < total) {
		// 		if (all_images[count].srcset) {
		// 			test_image.srcset = all_images[count].srcset;
		// 		}
		// 		test_image.src = all_images[count].src;

		// 		images_loop(total);
		// 	}
		// }, 10);

		// FOR version
		for(var i=0; i<total; i++){
			var test_image = new Image();


			test_image.onload = img_loaded;
			test_image.onerror = img_loaded;

			if (all_images[i].srcset) {
				test_image.srcset = all_images[i].srcset;
			}

			test_image.src = all_images[i].src;
		}
	}

	// Get all images
	$("*").each(function () {
		var element = $(this);

		if (element.is("img") && element.attr("src")) {
			all_images.push({
				src: element.attr("src"),
				element: element[0]
			});
		}

		$.each(hasImageProperties, function (i, property) {
			var propertyValue = element.css(property);
			var match;

			if (!propertyValue) {
				return true;
			}

			match = match_url.exec(propertyValue);
			
			if (match) {
				all_images.push({
					src: match[2],
					element: element[0]
				});
			}
		});

		$.each(hasImageAttributes, function (i, attribute) {
			var attributeValue = element.attr(attribute);

			if (!attributeValue) {
				return true;
			}

			all_images.push({
				src: element.attr("src"),
				srcset: element.attr("srcset"),
				element: element[0]
			});
		});
	});

	total = all_images.length;

	// Start preloader or exit
	if (total === 0) {
		done_loading();
	} else {
		images_loop(total);
	}
};
},{}],8:[function(require,module,exports){
var letter_class_default = "letter",
	word_class_default = "word",
	animation_time_default = 700;



// =======================================
// Wrap project title letters with <span>
// =======================================
var prepareTitles = function (title_container, animation_time, letter_class, word_class){
	var letters = $.trim(title_container.text()),
		letters_amount = letters.length,
		letter_class = letter_class || letter_class_default,
		word_class = word_class || word_class_default,
		animation_time = animation_time/1000 || animation_time_default/1000,
		delay_base = animation_time/letters_amount,
		new_title = "";

	// Remove original text
	title_container.html("");

	// Create new wrapped text
	for(var i = 0; i < letters_amount; i++){
		var letter_delay = (delay_base)*(i+1),
			css_delay = "style='animation-delay: " + letter_delay + "s'",
			text = "<span class='" + letter_class + "' " + css_delay + ">" + letters[i] + "</span>";

		if(i==0){
			text = "<span class='"+word_class+"'>" + text;
		}
		if(letters[i] == " " || letters[i] == "&nbsp;"){
			text = "</span><span class='"+word_class+"'>";
		}
		if(i == letters.length-1) {
			text = text + "</span>";
		}

		new_title += text;
	}

	title_container.append(new_title);
}



// =======================================
// Slider processing
// =======================================
var createSlider = function(slider_container, animation_time, animation_class, letter_class, word_class){
	// Set static parameters
	var slider_container = slider_container || ".portfolio-slider",
		slider = $(slider_container),
		previews = slider.find(".portfolio-preview"),
		projects_wrapper = slider.find(".portfolio-projects"),
		projects = projects_wrapper.find(".project"),
		control_buttons = slider.find(".portfolio-button"),
		letter_class = letter_class || letter_class_default,
		word_class = word_class || word_class_default,
		animation_time = animation_time || animation_time_default,
		animation_class = animation_class || "show";

	// Show active project title and technologies letters
	projects_wrapper.find(".active ." + letter_class).addClass("show");


	// Control buttons handler
	$(control_buttons).on("click", function(e){
		e.preventDefault();

		// Dynamically set CLICKED button parameters
		var this_button = $(this),
			this_thumbnails = this_button.next().find(".portfolio-thumbnails__thumbnail"),
			this_active_thumb = this_thumbnails.filter(".active"),
			this_next_index = this_thumbnails.index(this_active_thumb);

		// Set SIBLING (other) button parameters
		var other_button = this_button.parent().siblings().find(".portfolio-button"),
			other_thumbnails = other_button.next().find(".portfolio-thumbnails__thumbnail"),
			other_active_thumb = other_thumbnails.filter(".active"),
			other_next_index = other_thumbnails.index(other_active_thumb);

		// Set main preview and project indexes
		var active_preview = previews.filter(".active"),
			next_preview_index = previews.index(active_preview),
			active_project = projects.filter(".active"),
			next_project_index = projects.index(active_project);


		// If next button was clicked, then we need to increment indexes, else - decrement
		if(this_button.hasClass("portfolio-button_next")) {
			next_project_index = (next_project_index >= projects.length-1) ? 0 : next_project_index+1;
			this_next_index = this_next_index >= this_thumbnails.length-1 ? 0 : this_next_index+1;
			other_next_index = (other_next_index >= other_thumbnails.length-1) ? 0 : other_next_index+1;

			next_preview_index = (next_preview_index >= previews.length-1) ? 0 : next_preview_index+1;
		} else {
			next_project_index--;
			this_next_index--;
			other_next_index--;

			next_preview_index--;
		}

		// MAIN FUNCTIONS
		function lock_buttons(){
			this_button.prop("disabled", true);
			other_button.prop("disabled", true);

			setTimeout(function(){
				this_button.prop("disabled", false);
				other_button.prop("disabled", false);
			}, animation_time);
		}

		function change_thumbs(){
			var this_next_thumb = this_thumbnails.eq(this_next_index),
				other_next_thumb = other_thumbnails.eq(other_next_index);

			this_next_thumb.removeClass("move-down").addClass("active move-up");
			this_active_thumb.removeClass("active move-down").addClass("move-up");

			other_next_thumb.removeClass("move-up").addClass("active move-down");
			other_active_thumb.removeClass("active move-up").addClass("move-down");
		}

		function change_preview(){
			var next_preview = previews.eq(next_preview_index);

			active_preview.removeClass("active");
			next_preview.addClass("active");
		}

		function change_project(){
			var next_project = projects.eq(next_project_index),
				letters = next_project.find("." + letter_class);

			active_project
				.removeClass("active")
				.find("." + letter_class)
				.removeClass("show");

			next_project.addClass("active");
			letters.addClass("show");
		}


		change_thumbs();
		change_project();
		change_preview();
		lock_buttons();
	});

};



module.exports = {
	prepareTitles : prepareTitles,
	createSlider : createSlider
}
},{}],9:[function(require,module,exports){
var verticalParallax = function(container_selector, layer_selector, mobile_layer){
	var container_selector = container_selector || "#scene",
		layer_selector = layer_selector || ".layer",
		mobile_fallback = mobile_layer || 0,
		layers = $(container_selector).find(layer_selector);

	layers.css({"will-change":"transform"});

	$(window).scroll(function() {
		var scrollPos = $(this).scrollTop();

		if($(window).width() <= window.hm.tabletSize){

			layers.eq(mobile_fallback).css({
				"transform" : "translate3d(0," + ( (scrollPos/(5 + 2*mobile_fallback)) ) + "px,0)"
			})


		} else {

			layers.each(function(){
				var layer = $(this);

				if(layer.index() !=0 ) {
					layer.css({
						"transform" : "translate3d(0," + ( (scrollPos/(5 + 2*layer.index())) ) + "px,0)"
					});
				}
			});

		}

		// Optional: header scroll-fadeout
		$(container_selector).css({
			"opacity" : 1-(scrollPos/720)
		});

	});

};

module.exports = {
	createParallax : verticalParallax
}
},{}]},{},[1]);
